import { defineNuxtRouteMiddleware, abortNavigation } from '#imports';
import { usePageContent } from '@/composables/usePageContent';
import { useAnchorTag } from '@/composables/useAnchorTag';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { handlePageData } = usePageContent();
  const { validateAnchorRoute, scrollToAnchor } = useAnchorTag();

  try {
    const isAnchorChange = validateAnchorRoute(to, from);
    if (isAnchorChange) return scrollToAnchor(to.hash);

    await handlePageData(to, from);
  } catch (error) {
    console.error(error);

    return abortNavigation({
      statusCode: 404,
      message: 'Page not found',
    });
  }
});
